import { Icon, Popover } from '@akiunlocks/perseus-ui-components';

function Share() {
  const shareUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    return true;
  };

  return (
    <Popover
      id="share-tooltip"
      clickedContent="Copied to clipboard"
      content="Share these results"
      delay={{ show: 100, hide: 100 }}
    >
      <Icon className="text-primary" name="share-fill" onClick={() => shareUrl()} />
    </Popover>
  );
}

export default Share;
