import { useNavigate } from 'react-router-dom';
import { Button } from '@akiunlocks/perseus-ui-components';

import { ENV, envFeature } from 'lib/utils/featureFlag';

export default function NoMatch() {
  const navigate = useNavigate();
  return (
    <div className="m-8">
      <h1>404</h1>
      <h3>The page you are looking for does not exist</h3>
      {envFeature(
        [ENV.DEV],
        <p>You have to open the home page with a dvc hash id as param, like: {window.location.href}:dvcHashId</p>,
        null,
      )}
      <Button className="my-4" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  );
}
