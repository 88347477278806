import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Notification } from '@akiunlocks/perseus-ui-components';

import ErrorPage from 'components/Errors/ErrorPage';
import Home from 'containers/Home/Home';
import NoMatch from 'components/Errors/NoMatch';

import './App.scss';

function App() {
  const router = createBrowserRouter([
    {
      path: '/:hashId',
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    { path: '*', element: <NoMatch /> },
  ]);

  return (
    <div className="app">
      <Notification.Container limit={3} draggable={false} newestOnTop hideProgressBar={false} />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
