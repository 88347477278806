export const FILTER_NAMES = {
  AUDIENCE: 'Audience',
  COUNTDOWN: 'Countdown',
  DAY_PART: 'Day Part',
  DIRECT_TARGETING_KEY: 'DTK',
  DMACODE: 'DMA',
  OCCASION: 'Countdown',
  PERIOD: 'Period',
  PRODUCT_NAME: 'Product',
  REGION: 'Region',
  RETAILER: 'Retailer',
  STATECODE: 'State',
  WEATHER_CONDITION: 'Weather',
};
