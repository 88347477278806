export const FETCH_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const PROJECT_TYPE = {
  VIDEO: 'VIDEO',
  DISPLAY: 'DISPLAY',
  RICH_MEDIA: 'RICH_MEDIA',
};

export const PRODUCT_TYPE_PROPS = {
  DISPLAY: {
    icon: 'image',
    label: 'Display',
    variant: 'blue',
  },
  DOOH: {
    icon: 'per-dooh',
    label: 'DOOH',
    variant: 'cyan',
  },
  RICH_MEDIA: {
    icon: 'tb-player-play',
    label: 'Rich Media',
    variant: 'pink',
  },
  VIDEO: {
    icon: 'camera-video',
    label: 'Video',
    variant: 'purple',
  },
};

// Only interstitial subtype is needed for now.
// If we need other subtypes in the future, we can change this to an object.
export const INTERSTITIAL_BANNER = 'INTERSTITIAL_BANNER';

export const PRODUCT_SUBTYPE_LABELS = {
  ANIMATED_BANNER: 'Animated Banner',
  BANNER: 'Banner',
  INTERSTITIAL_BANNER: 'Interstitial',
  STATIC_BANNER: 'Static Banner',
  VIDEO: 'Video',
};
