import { Container } from '@akiunlocks/perseus-ui-components';

import './Header.scss';

function Header() {
  return (
    <header>
      <Container />
    </header>
  );
}

export default Header;
