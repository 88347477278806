const EMPTY_VALUE = '_';

export function loadFilters(data) {
  const filters = {};
  if (data.dnads) {
    Object.entries(data.dnads).forEach(([key, values]) => {
      if (!filters[key]) {
        filters[key] = [];
      }
      values.forEach(value => {
        filters[key].push({
          value,
          urlValue: value.toLowerCase(),
          checked: true,
        });
      });
    });
  }
  return filters;
}

function resetFilter(filters, key, checked) {
  filters[key]?.forEach(filterValue => {
    const value = filterValue;
    value.checked = checked;
  });
}

export function applyFilters(variants, filters) {
  if (variants) {
    const checkedFilters = Object.entries(filters).reduce((acc, [key, filterValues]) => {
      acc[key] = filterValues.filter(entry => entry.checked).map(({ value }) => value);
      return acc;
    }, {});

    return variants.filter(variant => {
      return Object.entries(checkedFilters).every(([key, values]) => {
        return values.includes(variant.dnads[key]);
      });
    });
  }
  return [];
}

/**
 * It updates the filters depending on the key and values that searchParams has.
 * @param {URLSearchParams} searchParams
 * @param {object} filters
 */
export function updateFiltersState(searchParams, filters) {
  const newFilters = { ...filters };
  Object.keys(newFilters).forEach(filterKey => {
    const urlKey = filterKey.toLowerCase();
    if (searchParams.has(urlKey)) {
      // we set everything as false first
      resetFilter(newFilters, filterKey, false);

      if (searchParams.get(urlKey) !== EMPTY_VALUE) {
        searchParams
          .get(urlKey)
          .split(',')
          .forEach(value => {
            if (!value) {
              return;
            }
            const foundValue = newFilters[filterKey].find(filterValue => filterValue.urlValue === value.toLowerCase());
            if (foundValue) {
              foundValue.checked = true;
            }
          });
      }
    } else {
      // if search params does not have the key, it means that all are selected
      resetFilter(newFilters, filterKey, true);
    }
  });
  return newFilters;
}

/**
 * The serialize function here would be responsible for creating an object
 * of { key: "value1,value2" } pairs from the fields in the filter
 * @param {object} filters
 * @param {boolean} includeEmpty
 * @returns {object}
 */
export function serializeFilters(filters, includeEmpty = false) {
  const serializedFilters = {};
  Object.entries(filters).forEach(([key, values]) => {
    const checkedValues = values.filter(({ checked }) => checked);
    if (includeEmpty && checkedValues.length === 0) {
      serializedFilters[key.toLowerCase()] = EMPTY_VALUE;
    } else if (checkedValues.length > 0 && checkedValues.length < values.length) {
      serializedFilters[key.toLowerCase()] = checkedValues.map(({ value }) => value).join(',');
    }
  });
  return serializedFilters;
}
