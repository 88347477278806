export const SAMPLES_BUTTON_GROUP = [
  {
    id: 'auto',
    value: 0,
    icon: 'CgMaximize',
    tooltip: 'Auto',
    variant: 'light',
  },
  {
    id: 'one-column',
    value: 1,
    icon: 'FaSquareFull',
    tooltip: 'One column',
    variant: 'light',
  },
  {
    id: 'two-columns',
    value: 2,
    icon: 'TfiLayoutColumn2Alt',
    tooltip: 'Two columns',
    variant: 'light',
  },
  {
    id: 'three-columns',
    value: 3,
    icon: 'TfiLayoutColumn3Alt',
    tooltip: 'Three columns',
    variant: 'light',
  },
  {
    id: 'four-columns',
    value: 4,
    icon: 'TfiLayoutColumn4Alt',
    tooltip: 'Four columns',
    variant: 'light',
  },
];
