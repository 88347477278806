import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@akiunlocks/perseus-ui-components';

import { HomeContext } from 'containers/Home/HomeProvider';
import { FILTER_NAMES } from 'lib/constants/filters';

import './filter.scss';

function Filter({ filterKey }) {
  const { filters, onFilterChange, onFilterAllChange } = useContext(HomeContext);
  const options = filters[filterKey];

  const filterName = FILTER_NAMES[filterKey] || filterKey.charAt(0).toUpperCase() + filterKey.slice(1).toLowerCase();

  return (
    <div className="filter">
      <div>
        <span>{filterName}</span>
      </div>
      <div>
        <Checkbox
          id={`${filterKey}-all`}
          label="All"
          checked={options.every(filterValue => filterValue.checked)}
          onChange={({ target }) => onFilterAllChange(filterKey, target.checked)}
          title="All"
        />
      </div>
      {options.map(filterValue => {
        return (
          <div key={filterValue.urlValue}>
            <Checkbox
              id={filterValue.urlValue}
              checked={filterValue.checked}
              label={filterValue.value}
              onChange={({ target }) => onFilterChange(filterKey, filterValue, target.checked)}
              title={filterValue.value}
            />
          </div>
        );
      })}
    </div>
  );
}

Filter.propTypes = {
  filterKey: PropTypes.string.isRequired,
};

export default Filter;
