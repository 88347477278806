import { useContext } from 'react';
import { Chip, Col, Container, Row, Spinner, ToggleButtonGroup } from '@akiunlocks/perseus-ui-components';

import Main from 'components/Content/Main/Main';
import Sidebar from 'components/Content/Sidebar/Sidebar';
import Share from 'components/Content/Share/Share';
import PreviewButtons from 'components/Content/PreviewButtons/PreviewButtons';
import { HomeContext } from 'containers/Home/HomeProvider';

import { FETCH_STATUS, PRODUCT_TYPE_PROPS, PRODUCT_SUBTYPE_LABELS } from 'lib/constants/constants';
import { SAMPLES_BUTTON_GROUP } from 'lib/constants/content';

import './content.scss';

function Content() {
  const { data, isInterstitial, numColumns, setInterstitialSize, setIsLandscape, setNumColumns, status } =
    useContext(HomeContext);

  if (status === FETCH_STATUS.LOADING) {
    return (
      <div className="general-message">
        <Spinner variant="primary" />
      </div>
    );
  }
  if (status === FETCH_STATUS.ERROR) {
    return (
      <div className="general-message">
        <h2>Oops! Something went wrong</h2>
        <p>We are sorry, but we could not load the data. Please, try again later.</p>
      </div>
    );
  }

  const buttonGroupOptions = SAMPLES_BUTTON_GROUP.map(option => ({
    ...option,
    onChange: ({ target }) => {
      const numColumns = Number(target.value);
      setNumColumns(numColumns);
      if (isInterstitial && numColumns !== 0) {
        setInterstitialSize(null);
        setIsLandscape(false);
      }
    },
  }));

  const productTypeProps = PRODUCT_TYPE_PROPS[data.productType];
  const productSubtype = PRODUCT_SUBTYPE_LABELS[data.productSubtype];

  return (
    <Container>
      <Row>
        {!data.compositeSetHashId && <div className="project-title">{data.title}</div>}
        {data.compositeSetHashId && (
          <a
            className="project-title"
            href={`${process.env.REACT_APP_PERSEUS_URL}/campaign/${data.compositeSetHashId}/review`}
            target="_blank"
            rel="noreferrer"
          >
            {data.title}
          </a>
        )}
      </Row>
      <Row>
        <div className="second-row">
          <div className="project-subtitle-container">
            <div className="subtitle">{data.subtitle}</div>
            {productTypeProps && (
              <Chip
                icon={productTypeProps.icon}
                label={
                  <span>
                    <strong>{productTypeProps.label}</strong> - {productSubtype}
                  </span>
                }
                variant={productTypeProps.variant}
              />
            )}
          </div>
          <div className="options-container">
            <div className="options">
              {isInterstitial && <PreviewButtons />}
              <ToggleButtonGroup
                buttons={buttonGroupOptions}
                className="columns-toggle"
                name="columns"
                onChange={setNumColumns}
                size="sm"
                type="radio"
                value={numColumns}
              />
              <Share />
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={4} md={2} className="ps-0">
          <Sidebar />
        </Col>
        <Col xs={8} md={10} className="text-center">
          <Main samplesPerRow={numColumns || undefined} />
        </Col>
      </Row>
    </Container>
  );
}

export default Content;
