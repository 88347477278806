import PropTypes from 'prop-types';
import { Icon, Popover } from '@akiunlocks/perseus-ui-components';

import './variant.scss';

function Download({ variant }) {
  return (
    <div className="footer">
      <Popover id={`download-${variant.variantName}`} content="Download">
        <a download href={variant.url} aria-label="Download">
          <Icon name="download" size={16} />
        </a>
      </Popover>
    </div>
  );
}

Download.propTypes = {
  variant: PropTypes.shape({
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Download;
