const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const ENV = {
  DEV: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

/**
 * Allows an element to be returned if the environment is in the white list of approved environments.
 * @param {array} env A list of approved environments to return the feature ['development', 'staging', 'production']
 * @param {element} enabledElement The element to be returned if ok
 * @param {element} disabledElement The element to be returned if not a valid environment. Defaults to nothing.
 */
export const envFeature = (env, enabledElement, disabledElement = null) => {
  return env.includes(ENVIRONMENT) ? enabledElement : disabledElement;
};
