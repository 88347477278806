import Header from 'components/Header/Header';
import Content from 'components/Content/Content';

import { HomeProvider } from 'containers/Home/HomeProvider';
import Page from 'containers/Page/Page';

function Home() {
  return (
    <Page>
      <HomeProvider>
        <div>
          <Header />
          <Content />
        </div>
      </HomeProvider>
    </Page>
  );
}
export default Home;
