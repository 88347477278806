import { useContext } from 'react';
import { PrismaPreviewButtons } from '@akiunlocks/perseus-ui-components';

import { HomeContext } from 'containers/Home/HomeProvider';

import { DEFAULT_SIZE } from 'lib/constants/interstitials';

import './previewButtons.scss';

function PreviewButtons() {
  const { interstitialSize, setInterstitialSize, isLandscape, setIsLandscape, setNumColumns } = useContext(HomeContext);

  return (
    <PrismaPreviewButtons
      className="preview-buttons"
      value={interstitialSize}
      onChange={value => {
        setInterstitialSize(value);
        setNumColumns(0);
      }}
      onControlClick={key => {
        setInterstitialSize(interstitialSize || DEFAULT_SIZE);
        setNumColumns(0);
        if (key === 'landscape') {
          setIsLandscape(!isLandscape);
        }
      }}
    />
  );
}

export default PreviewButtons;
