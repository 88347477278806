import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PrismaPreview } from '@akiunlocks/perseus-ui-components';

import { HomeContext } from 'containers/Home/HomeProvider';
import { notifyHotspotClicked, notifyCloseClicked, replaceClickPlaceholders } from './utilities';

const PRISMA_JS = 'prisma.js';

function VariantRichMedia({ loading, maxWidth = undefined, setLoading, variant }) {
  const { data, isInterstitial, interstitialSize, isLandscape } = useContext(HomeContext);
  const [prismaJson, setPrismaJson] = useState(null);

  const useInterstitialSize = isInterstitial && interstitialSize;

  useEffect(() => {
    async function getPrismaJson() {
      const response = await fetch(variant.url);
      const json = await response.json();
      setPrismaJson(replaceClickPlaceholders(json, data.hotspots));
      setLoading(false);
    }
    if (variant.url) {
      getPrismaJson();
    }
  }, [variant.url]);

  // get base url from media url
  const baseUrl = variant.url.substring(0, variant.url.lastIndexOf('/') + 1);
  if (loading) {
    return null;
  }

  const prismaConfig = {
    clickCb: clickPlaceholder => notifyHotspotClicked(data.hotspots, clickPlaceholder),
    onClose: notifyCloseClicked,
  };
  if (useInterstitialSize) {
    prismaConfig.isLandscape = isLandscape;
    // rotate preview if landscape is set
    prismaConfig.responsive = isLandscape
      ? { height: interstitialSize.width, width: interstitialSize.height }
      : interstitialSize;
  }

  return (
    <PrismaPreview
      baseUrl={baseUrl}
      canvasId={variant.variantId.toString()}
      deviceButtonsConfig={{
        value: interstitialSize,
      }}
      buttonsVariant="outline-primary"
      fileName={variant.variantName}
      prismaProject={prismaJson}
      prismaUrl={`${baseUrl}${PRISMA_JS}`}
      maxWidth={useInterstitialSize ? undefined : maxWidth}
      prismaConfig={prismaConfig}
    />
  );
}

VariantRichMedia.propTypes = {
  loading: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  setLoading: PropTypes.func.isRequired,
  variant: PropTypes.shape({
    variantId: PropTypes.number.isRequired,
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default VariantRichMedia;
