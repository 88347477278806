import { useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Pagination } from 'react-bootstrap';

import { HomeContext } from 'containers/Home/HomeProvider';
import { PAGINATION_ACTIONS } from 'containers/Home/pagination';

import './paginationButtons.scss';

function PaginationButtons() {
  const { pagination, setPagination } = useContext(HomeContext);
  const { page, pageSize, totalItemsCount } = pagination;

  const totalPages = Math.ceil(totalItemsCount / pageSize) - 1;

  const handlePageChange = newPage => {
    setPagination({ type: PAGINATION_ACTIONS.SET_PAGE, payload: newPage });
  };

  return (
    <div className="pagination-container">
      <Pagination size="sm">
        <Pagination.First onClick={() => handlePageChange(0)} disabled={page === 0} />
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 0} />

        {[...Array(totalPages + 1).keys()].map(item => (
          <Pagination.Item key={item} active={item === page} onClick={() => handlePageChange(item)}>
            {item + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
      </Pagination>
    </div>
  );
}

export default PaginationButtons;
