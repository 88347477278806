import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover, Spinner } from '@akiunlocks/perseus-ui-components';

import { PROJECT_TYPE } from 'lib/constants/constants';
import VariantDisplay from './VariantDisplay';
import VariantRichMedia from './VariantRichMedia';
import VariantVideo from './VariantVideo';

import './variant.scss';

function Variant({ maxWidth = undefined, type, variant }) {
  const [loading, setLoading] = useState(true);

  return (
    <div data-src={variant.url} className="variant" data-srctype="image">
      {loading &&
        type !== PROJECT_TYPE.RICH_MEDIA && ( // Rich media has its own loading spinner
          <div className="loading">
            <Spinner className="spinner" variant="primary" />
          </div>
        )}
      {!loading && (
        <div className={`info info-${type.toLowerCase()}`}>
          <Popover id={`info-${variant.variantId}`} content={variant.variantName}>
            <Icon name="info-circle" size={12} />
          </Popover>
        </div>
      )}
      {type === PROJECT_TYPE.DISPLAY && (
        <VariantDisplay maxWidth={maxWidth} setLoading={setLoading} variant={variant} />
      )}
      {type === PROJECT_TYPE.RICH_MEDIA && (
        <VariantRichMedia loading={loading} maxWidth={maxWidth} setLoading={setLoading} variant={variant} />
      )}
      {type === PROJECT_TYPE.VIDEO && <VariantVideo setLoading={setLoading} variant={variant} />}
    </div>
  );
}

Variant.propTypes = {
  maxWidth: PropTypes.number,
  type: PropTypes.string.isRequired,
  variant: PropTypes.shape({
    variantId: PropTypes.number.isRequired,
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Variant;
