import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Download from 'components/Content/Variant/Download';

import './variant.scss';

function VariantVideo({ setLoading, variant }) {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className="video" controls src={variant.url}>
        Your browser does not support the video tag.
      </video>
      <Download variant={variant} />
    </>
  );
}

VariantVideo.propTypes = {
  setLoading: PropTypes.func.isRequired,
  variant: PropTypes.shape({
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default VariantVideo;
