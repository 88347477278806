import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { HomeContext } from 'containers/Home/HomeProvider';
import Variant from '../Variant/Variant';
import PaginationButtons from '../PaginationButtons';

import './main.scss';

function Main({ samplesPerRow = undefined }) {
  const { pagedItems, type } = useContext(HomeContext);
  const containerRef = useRef(null);
  const [sampleMaxWidth, setSampleMaxWidth] = useState();
  const hasResults = !!pagedItems.length;

  useEffect(() => {
    if (containerRef?.current?.clientWidth) {
      const remInPx = parseInt(getComputedStyle(document.documentElement).fontSize, 10);
      const gap = 2 * remInPx + 2; // 1rem of preview padding + 1rem of gap between samples + 2px of border
      setSampleMaxWidth(containerRef.current.clientWidth / (samplesPerRow || 1) - gap);
    }
  }, [samplesPerRow]);

  return (
    <div
      className={classnames('main', {
        auto: hasResults && !samplesPerRow,
        columns: hasResults && samplesPerRow,
      })}
      style={samplesPerRow ? { gridTemplateColumns: `repeat(${samplesPerRow}, 1fr)` } : {}}
      ref={containerRef}
    >
      {!hasResults && (
        <div className="no-results">
          <h2>No results found</h2>
          <p>We could not find any matches for your search or filter criteria.</p>
          <p>Please, try changing them.</p>
        </div>
      )}
      {pagedItems.map(variant => (
        <Variant key={variant.variantName} type={type} variant={variant} maxWidth={sampleMaxWidth} />
      ))}
      <PaginationButtons className="pagination" />
    </div>
  );
}

Main.propTypes = {
  samplesPerRow: PropTypes.number,
};

export default Main;
