import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { PageContext } from './PageContext';

function Page({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const addValue = useCallback(
    (key, value) => {
      setSearchParams(searchParams => {
        searchParams.append(key, value);
        return searchParams;
      });
    },
    [searchParams, setSearchParams],
  );

  const value = useMemo(() => ({ addValue, searchParams, setSearchParams }), [addValue, searchParams]);

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
