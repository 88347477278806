import { useContext } from 'react';
import { HomeContext } from 'containers/Home/HomeProvider';
import Filter from '../Filter/Filter';

import './sidebar.scss';

function Sidebar() {
  const { filters, pagination } = useContext(HomeContext);
  return (
    <div className="sidebar">
      <h4 className="filters-title">Filters</h4>
      <div className="fs-7 text-muted">
        {pagination.totalItemsCount === 1 ? `1 variant` : `${pagination.totalItemsCount} variants`}
      </div>
      {Object.keys(filters).map(filterKey => {
        return <Filter key={filterKey} filterKey={filterKey} />;
      })}
    </div>
  );
}

export default Sidebar;
