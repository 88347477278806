export const PAGINATION_ACTIONS = {
  SET_ALL_ITEMS: 'SET_ALL_ITEMS',
  SET_PAGE: 'SET_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
};

export const INITIAL_PAGINATION_STATE = {
  items: [],
  page: 0,
  pageSize: 50,
  totalItemsCount: 0,
};

export function paginationReducer(state, action) {
  switch (action.type) {
    case PAGINATION_ACTIONS.SET_ALL_ITEMS:
      return {
        ...state,
        items: action.payload,
        page: INITIAL_PAGINATION_STATE.page,
        totalItemsCount: action.payload.length,
      };
    case PAGINATION_ACTIONS.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case PAGINATION_ACTIONS.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
}
